Ext.define('Admin.view.sales.Grid',{
    extend: 'Admin.view.template.Grid',
    xtype: 'adminsales',
    title: 'Sales Management',
    url: 'sales_path',
    form: 'adminsaleform',
    controller: 'sale',
    reference: 'sale-grid-view',
    itemId: 'sale-grid-view',
    disableConfig: {
        disableDefaultConfig: true,
        disableStatusColumn: true,
        disableIsActiveColumn: true,
        disablePagination: false,
        disable: {
            search: true,
            update: false,
            create: true,
            destroy: true
        }
    },
    customTBarItems: [
        {
            xtype: 'datefield',
            fieldLabel: 'Sale From',
            editable: false,
            reference: 'saleStartDate',
            labelWidth: 70,
            width: 200,
            value: new Date()
        },
        {
            xtype: 'datefield',
            fieldLabel: 'To',
            editable: false,
            reference: 'saleEndDate',
            labelWidth: 30,
            width: 160,
            value: new Date()
        },
        // {
        //     xtype: 'combobox',
        //     reference: 'saleMachine',
        //     fieldLabel: 'Machine',
        //     displayField: 'name',
        //     valueField: 'id',
        //     store: {
        //         type: 'adminstore',
        //         url: Routes.option_machines_path({all: true})
        //     },
        //     forceSelection: true,
        //     position: 2,
        //     labelWidth: 60,
        //     value: 'all',
        //     queryMode: 'local',
        //     width: 160
        // },
        {
            xtype: 'combobox',
            reference: 'saleProduct',
            fieldLabel: 'Product',
            displayField: 'name',
            valueField: 'id',
            store: {
                type: 'adminstore',
                url: Routes.option_products_path({all: true})
            },
            forceSelection: true,
            position: 2,
            labelWidth: 60,
            value: 'all',
            queryMode: 'local',
            width: 250
        },
        {
            xtype: 'combobox',
            reference: 'saleMachine',
            fieldLabel: 'Machine',
            displayField: 'name',
            valueField: 'id',
            store: {
                type: 'adminstore',
                url: Routes.option_machines_path({all: true})
            },
            forceSelection: true,
            position: 2,
            labelWidth: 60,
            value: 'all',
            queryMode: 'local',
            width: 160
        },
        {
            xtype: 'checkbox',
            reference: 'saleError',
            boxLabel: 'Show Dispense Error Sales',
            itemId: 'sale-dispense-error'
            
        },
        {
            text: 'Show',
            handler: 'onShowSale'
        },
        {
            text: 'Export as CSV',
            handler: 'onExportAsCSV'
        }
    ],
    columns: [
        {
            text: 'SVM IDs',
            dataIndex: 'machine_name',
            width: 150
        },
        {
            text: 'Location',
            dataIndex: 'location_name',
            width: 200,
            sortable: false
        },
        {
            dataIndex: 'transaction_time',
            text: 'Date Purchase',
            width: 220,
            format: 'M d, Y, h:i A',
            xtype: 'datecolumn'
        },
        {
            dataIndex: 'product_name',
            text: 'Product Name',
            width: 200,
            sortable: false
        },
        {
            dataIndex: 'gross_amount',
            width: 150,
            text: 'Amount',
            align: 'right',
            sortable: false,
            renderer: function(value){
                Ext.util.Format.setConfig({thousandSeperator: '.'})
                return Ext.util.Format.currency(value, 'Rp', 0, false, ' ')
            }
        },
        {
            dataIndex: 'dispense_belt',
            text: 'Slot',
            align: 'center',
            menuDisabled: true,
            sortable: false
        },
        // {
        //     dataIndex: 'current_qty',
        //     text: 'Current QTY'
        // },
        {
            dataIndex: 'dispense_code',
            text: 'Status',
            menuDisabled: true,
            align: 'center',
            sortable: false
        },
        {
            dataIndex: 'dispense_status',
            text: 'Result',
            menuDisabled: true,
            align: 'center',
            sortable: false,
            width: 100,
            cellWrap: true
        },
        // {
        //     text: 'Dispense Details',
        //     menuDisabled: true,
        //     dataIndex: 'dispense_result',
        //     width: 250,
        //     cellWrap: true
        // },
        {
            dataIndex: 'transaction_id',
            text: 'Transaction ID',
            width: 300
        },
        {
            dataIndex: 'order_id',
            text: 'Order ID',
            width: 150
        }
    ],
    listeners: {
        beforerender: function(grid, options, successful){
            this.addDocked(new Ext.Toolbar({
                items: [
                    {
                        itemId: 'total-count-sales',
                        fieldLabel: 'Total Number of Sales',
                        xtype: 'displayfield',
                        labelStyle: "color: red; font-weight: bold; font-size: 16px",
                        fieldStyle: "color: red; font-weight: bold; font-size: 16px",
                        value: 0,
                        width: 300,
                        labelWidth: 190,
                        reference: 'totalSale',
                    },
                    {
                        itemId: 'total-sales',
                        fieldLabel: 'Total Sales Revenue',
                        labelStyle: "color: red; font-weight: bold; font-size: 16px",
                        fieldStyle: "color: red; font-weight: bold; font-size: 16px",
                        xtype: 'displayfield',
                        value: 0,
                        width: 300,
                        labelWidth: 170,
                        reference: 'totalSaleAmount',
                    }
                ]
            }), -1);
        },
        afterlayout: function(view){
            const me=this;
            const store = view.getStore()
            try {
                store.on('load', function(s,t,u,v){
                    // console.log(s,t,u,v)
                    const {total, total_sale} = s.responseData;
                    me.down('#total-count-sales').setValue(total)
                    Ext.util.Format.setConfig({thousandSeperator: '.'})
                    current_format = Ext.util.Format.currency(total_sale, 'Rp', 0, false, ' ')
                    me.down('#total-sales').setValue(current_format)
                })
            } catch (error) {
                console.log(error)
            }
        },
        refresh: function(){
            console.log('sdfhjksadhfs')
        }
    }
})